@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --Foundation-Grey-Normal: #151515;
  --Grey: #4d4d4d;
  --Grey2: #626262;
}

html {
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "__Inter_aaf875", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  background: #f4f3ec;
  font-size: 16px;
  overflow-x: hidden;
}

h1,
h2,
h3 {
  letter-spacing: -2px;
  line-height: 1.2;
}

h4,
h5,
h6 {
  letter-spacing: -1px;
  line-height: 1.2;
}

p {
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 1.2;
}

// Links underline Hover
p a,
li a {
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: background-size 0.5s;
  text-decoration: none !important;
  background-position: right;

  &:hover {
    background-size: 0% 100%;
  }
}

// reverse (initially no underline)
header li a,
footer p a,
footer ul li a {
  background-size: 0% 100%;
  background-position: left;
  transition: 400ms;

  &:hover {
    background-size: 100% 100%;
  }
}

// Header menu
header ul li a {
  transition: 400ms;

  &:hover {
    color: #009e4c;
    transition: 400ms;
  }
}

// CTA Buttons Hover
.cta_btn {
  transition: 600ms;
  &:hover {
    background: var(--Foundation-Grey-Normal);
    transition: 600ms;
    color: #fff;
    border-color: var(--Foundation-Grey-Normal);
  }

  &.bg-black {
    &:hover {
      background: var(--Grey);
    }
  }
}
// hide scrollbars with overflow auto
.timeline_overflow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}
.timeline_overflow::-webkit-scrollbar {
  width: 1px;
  background-color: transparent;
  position: absolute;
}
.timeline_overflow::-webkit-scrollbar-thumb {
  background-color: transparent;
}

//form
.form-checkbox {
  accent-color: #009e4c;
  // box-shadow: none !important;
  // border-color: transparent !important;
}

input[type="checkbox"] {
  // height: auto;
  // width: auto;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none !important;
  background: #fff;
  position: relative;
  width: 16px;
  height: 16px;
  border-color: #009e4c !important;
}
input[type="checkbox"]::before,
input[type="checkbox"]::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  box-sizing: content-box;
  cursor: pointer;
  border: 1px solid #009e4c;
  border-radius: 3px;
}
input[type="checkbox"]:checked:after {
  height: 9px;
  width: 9px;
  border-left: none !important;
  border-bottom: none !important;
  background-color: #009e4c !important;
  transform: none !important;
  left: 4px !important;
  top: 4px !important;
  border-radius: 0px !important;
}

.forgot-password {
  color: #0047ff;
}

.forgot-password:hover {
  text-decoration: underline;
}

// nav animation
@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
    display: none;
  }
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out forwards;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
  /* opacity: 1; */
  opacity: 0;
}

@keyframes dropdownExpand {
  from {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
  to {
    opacity: 1;
    max-height: 1000px; /* Adjust height as needed */
    overflow: visible;
  }
}

@keyframes dropdownCollapse {
  from {
    opacity: 1;
    max-height: 1000px; /* Adjust height as needed */
    overflow: visible;
  }
  to {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
}

.expand {
  animation: dropdownExpand linear forwards;
  transition: 0.5s;
}

.collapse {
  animation: dropdownCollapse linear forwards;
  transition: 0.5s;
  margin-top: 0px !important;
}

// Subapage Hero
.subpage_hero {
  height: 550px;
}

// Disabled Buttons
.disabled {
  background: #aaa;
  pointer-events: none;
}

// CTA Buttons
.cta_btn {
  font-size: 16px;
}

// Marquee Slider
.rfm-marquee-container {
  margin: 0 -4rem;
  width: 100vw !important;
}
.rfm-marquee {
  min-width: auto !important;
}

// Onboarding Forms
.tinted {
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
}

select {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("/images/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: 97% center;
  padding-left: 8px;
}

.back_arrow {
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

//intl tel input
.PhoneInput {
  display: flex;
  padding: 16px 12px 8px 12px;
  gap: 4px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #767676;
  height: 45px;
  margin-bottom: 1rem;
  background-color: #fff;
  input {
    padding-bottom: 0px !important;
    height: 30px !important;
  }
  input:focus {
    outline: none;
  }
  .PhoneInputCountrySelectArrow,
  .PhoneInputCountryIcon {
    position: relative;
    top: -3px;
  }
  .PhoneInputCountryIcon {
    width: 18px !important;
    box-shadow: none !important;
  }
}

//cookie policy
.cky-btn-revisit-wrapper .cky-btn-revisit::before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url(/images/icon.png);
  background-size: 100% 100%;
  display: inline-block;
  height: 45px;
  width: 45px;
}

.cky-btn-revisit-wrapper .cky-btn-revisit img {
  display: none;
}

.cky-btn-revisit-wrapper {
  background-color: #009e4c;
  z-index: 98 !important;
}

// Home Featured Carousel
.slick-list {
  padding: 10px;
  border-radius: 15px;
  height: calc(100% - 10px);
}

.slick-slide {
  padding: 0 5px;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

body .slick-arrow {
  position: absolute;
  top: -5rem !important;
  right: 0;
  z-index: 10;
  background: #009e4c !important;
  border-radius: 100px;
  padding: 10px;
  width: 56px;
  height: 56px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  &.slick-next {
    img {
      transform: scale(-1);
    }
  }

  &.slick-prev {
    left: auto;
    right: 75px;
  }

  &.slick-disabled {
    background: #d3d3d3 !important;
    pointer-events: none;

    img {
      filter: invert(0);
    }

    &:hover {
      background: var(--Foundation-Grey-Normal) !important;

      img {
        filter: invert(1);
      }
    }
  }

  img {
    width: 24px;
    filter: invert(1);
  }

  &:hover {
    background: var(--Foundation-Grey-Normal) !important;
  }
}

// Cart product slider
.cart_slider {
  padding-bottom: 40px;
  .slick-track {
    margin: 0;
  }

  .slick-arrow {
    background: transparent !important;
    top: 75px !important;

    &.slick-disabled {
      background: transparent !important;
      opacity: 0.5;
    }

    &.slick-next {
      background: url(/images/cart-arrow-right.svg) no-repeat !important;
      background-size: contain !important;
      background-size: 18px !important;
      background-position: center !important;
    }

    &.slick-prev {
      background: url(/images/cart-arrow-left.svg) no-repeat !important;
      background-size: contain !important;
      background-size: 18px !important;
      background-position: center !important;
      left: 0;
    }
  }

  // .slick-slide.slick-current{
  //   .bg-white{
  //     border: 1px solid #009e4c;
  //   }
  // }
}

// About Timeline
.timeline_wrapper {
  .slick-list {
    overflow: visible;
  }

  .timeline_row {
    display: flex !important;
  }

  .timeline_card {
    position: relative;
    margin: 0 !important;
    height: 100%;
    display: block;

    &:empty::before,
    &:empty::after {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      height: 3.5rem;
      bottom: -3.5rem;
      width: 5px;
      background: #000;
      left: 40px;
    }

    &::after {
      content: "";
      position: absolute;
      height: 5px;
      bottom: -5.5rem;
      width: 120%;
      background: #000;
      left: 15px;
    }
  }

  p.date {
    margin-top: 0px !important;
    position: relative;
    z-index: 1;
    display: inline-block;
  }

  .slick-slide {
    margin: 0 !important;

    &:nth-child(even) {
      .timeline_card {
        &::before {
          bottom: auto;
          top: -3.5rem;
        }

        &::after {
          bottom: auto;
          top: -5.2rem;
        }
      }

      p.date {
        margin-top: 0 !important;
        margin-bottom: 0px !important;
      }
    }

    &:last-child {
      .timeline_card {
        &::after {
          opacity: 0;
        }
      }
    }
  }
}

// chat widget
#delphi-container {
  display: none !important;
}

#delphi-container.show {
  display: flex !important;
  position: fixed;
  bottom: calc(5rem + 20px);
  width: 300px !important;
  // height: 600px !important;
  /* display: block !important; */
  right: 15px;
  border-radius: 0.75rem;
  overflow: hidden;
  z-index: 9998 !important;
}

// chat widget
#delphi-container {
  display: none !important;
}

#delphi-container.show {
  display: flex !important;
  position: fixed;
  bottom: calc(5rem + 20px);
  width: 300px !important;
  // height: 600px !important;
  /* display: block !important; */
  right: 15px;
  border-radius: 0.75rem;
  overflow: hidden;
  z-index: 9998 !important;
}
// radio button
.form-radio {
  accent-color: #009e4c;
}

@media only screen and (min-width: 1550px) {
  .rfm-child > div {
    width: 100vw !important;
  }
}

@media only screen and (max-width: 1280px) {
  html {
    font-size: 0.8rem;
  }

  p {
    font-size: 16px;
  }

  // onboarding forms
  .form_hero {
    h1 {
      font-size: 5rem;
    }
  }

  // cart icon on header
  .header_cart_icon {
    z-index: 9998 !important;
    right: 2.5rem;
  }
}

@media only screen and (max-width: 991px) {
  // cart icon on header
  .header_cart_icon {
    right: 3rem;
    z-index: 9998 !important;

    svg {
      width: 40px;
    }

    .header_cart_quantity {
      width: 20px !important;
      height: 20px !important;
      left: 30px;
    }
  }
}

@media only screen and (max-width: 768px) {
  // onboarding forms
  .form_hero {
    h1 {
      font-size: 3.5rem;
    }
  }
}

@media only screen and (max-width: 640px) {
  h1,
  h2 {
    font-size: 2.5rem;
  }

  .subpage_hero {
    height: 250px;

    &.shop_hero {
      height: 380px;
    }

    h1 {
      font-size: 2.5rem !important;
      width: 100%;
    }
  }

  // CTA Buttons
  .cta_btn {
    height: 51px;
    font-size: 16px;
  }

  // Marquee Slider
  .rfm-marquee-container {
    margin: 0 -15px;
  }

  // onboarding forms
  .form_hero {
    height: 300px;
    h1 {
      font-size: 2.5rem;
    }
  }

  @keyframes dropdownExpand {
    from {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
    }
    to {
      opacity: 1;
      max-height: 2000px; /* Adjust height as needed */
      overflow: visible;
    }
  }

  //about timeline
  .timeline_overflow {
    .slick-arrow {
      // top: -45px !important;
      top: -102px !important;
      width: 45px;
      height: 45px;
      right: 0;
      left: auto;
      &.slick-prev {
        left: auto !important;
        right: 60px !important;
      }
    }
  }

  // Home featured carousel
  body .slick-arrow {
    top: -45px !important;
    width: 45px;
    height: 45px;
    left: 60px;

    &.slick-prev {
      left: 0;
      right: auto;
    }
  }

  // cart icon on header
  .header_cart_icon {
    right: 5rem;
    margin-top: 1px;
    z-index: 9998 !important;
  }

  .cart_slider {
    .slick-arrow {
      &.slick-next {
        right: 0 !important;
        left: auto;
        background-size: 14px !important;
      }
      &.slick-prev {
        background-size: 14px !important;
      }
    }
  }
  .timeline_wrapper {
    .slick-slider {
      padding-right: 15%;
    }
  }
}
